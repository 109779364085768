.ctx-home-title {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 2.8rem;
    color: #26174d;
}
.ctx-home-head {
    display: flex;
    flex-direction: row;
    gap: 24px;
}
.ctx-home-head > :first-child {
    flex-grow: 1;
}

#bookingsSectionId .card-header {
    border: 0;
}

.tabs-tab-list {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.tabs-tab-list > :first-child {
    flex-grow: 1;
}

.tabs-tab {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}
