.ctx-home-booking-activity-cards,
.ctx-home-booking-activity-metrics {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.ctx-home-booking-activity-cards > div,
.ctx-home-booking-activity-metrics > div {
    flex: 1;
}

.dashboard-app-card {
    border: 1px solid #cacaca;
    box-shadow: none !important;
    background: transparent !important;
}

.dashboard-app-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: black;
}

.dashboard-app-text {
    color: black;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    flex: 80%;
}

.dashboard-app-card > a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}

.dashboard-app-link {
    text-align: right;
    font-weight: bold;
    color: #26174d;
    flex: 100%;
}

.dashboard-app-right {
    flex: 20%;
}

.dashboard-app-logo-datacruncher {
    background-color: #f7d0eb;
    background-image: url('BI48px.svg');
    border-radius: 12px;
    width: 48px;
    height: 48px;
}

.dashboard-last-update {
    background-color: #e0e0e0;
    font-size: 1.2rem;
    line-height: 24px;
    padding: 0 12px;
    border-radius: 4px;
    font-weight: 600;
}
