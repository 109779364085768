.ctx-home-title {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 2.8rem;
    color: #26174d;
}
.ctx-home-head {
    display: flex;
    flex-direction: row;
    gap: 24px;
}
.ctx-home-head > :first-child {
    flex-grow: 1;
    gap: 12px;
}
.ctx-home-head > :last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    gap: 12px;
}

.ctx-home-booking-activity-cards,
.ctx-home-booking-activity-metrics {
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.ctx-home-booking-activity-cards > div,
.ctx-home-booking-activity-metrics > div {
    flex: 1;
}
.ctx-home-calendar-container {
    display: flex;
    /* align-items: center; */
    gap: 6rem;
}
.ctx-home-calendar-container > div {
    flex: 1;
    position: relative;
}
.ctx-calendar-progress-bar {
    width: 32px;
}
.ctx-calendar-progress-bar .progress-bar-inner {
    border-radius: 4px;
}
.ctx-card-header-channel-distribution {
    margin-block-end: 8px;
}
.ctx-progress-bar-channel-distribution {
    border-radius: 4px;
    height: 16px;
    display: flex;
}
.ctx-home-channel-distribution-legend {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-block-start: 12px;
}
.ctx-calendar-card-header {
    border-bottom: 0;
    padding-bottom: 0;
}
.ctx-calendar-card-header h2 {
    font-size: 1.2rem;
}
.ctx-progress-bar-legend {
    display: flex;
    align-items: center;
    gap: 8px;
}
.ctx-progress-bar-legend:before {
    content: '';
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background-color: #b5b5b5;
}
.ctx-progress-bar-legend--purple-900:before {
    background-color: #26174d;
}
.ctx-progress-bar-legend--purple-600:before {
    background-color: #7143b2;
}
.ctx-progress-bar-legend--purple-300:before {
    background-color: #ba88ee;
}
.ctx-progress-bar-legend--purple-100:before {
    background-color: #dfc5f9;
}
.ctx-progress-bar-legend-label {
    font-size: 1.4rem;
    font-weight: 500;
    color: #606060;
}
.ctx-progress-bar-legend-value {
    font-size: 1.2rem;
    font-weight: 700;
    color: #8a8a8a;
}

/* fixup style */
.calendar-body-date-text {
    transform: none;
    position: inherit;
}
